<!-- About Widget -->
<div class="widget about-widget">
    <div class="logo mb-30">
        <img src="assets/img/logo-3.png" alt="img">
    </div>
    <p>
        Being one of the excellent and ordained IT and digital solution providers rooted in Saint Vincent and the Grenadines, TC Limited helps to streamline 
        your business processes and implement digital strategies to build your brand and business. In the vast and confusing 
        digital marketplace, we place our whole souls to accommodate our client's marketing objectives for progressive 
        improvements that lead to more sales and inquiries.
    </p>
</div>
<!-- Contact information Widget -->
<div class="widget contact-info-widget">
    <h5 class="widget-title">Contact Us</h5>
    <ul>
        <li>
            TC Limited | Suite 305, Griffith Corporate Centre, Beachmont, OP.O. Box 1510, Kingstown, Saint Vincent & the Grenadines.
        </li>
        <!-- <li>
            <a href="tel:+971506052555"><i class="far fa-phone"></i>+971 5 06 052 555</a>
        </li> -->
        <li>
            <i class="far fa-envelope-open"></i>
            cs@trusttc.com
        </li>
    </ul>
</div>
<!-- tweets Widget -->
<!-- <div class="widget tweets-widget">
    <h5 class="widget-title">Recent tweets</h5>
    <ul>
        <li>
            <a href="#">
                <i class="fab fa-twitter"></i>
                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium...
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fab fa-twitter"></i>
                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium...
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fab fa-twitter"></i>
                <span>@username:</span> Sedut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium...
            </a>
        </li>
    </ul>
</div> -->
<!-- Social Link -->
<div class="widget social-link">
    <h5 class="widget-title">Follow us</h5>
    <ul>
        <li><a href="https://www.facebook.com/TC-Limited-102728182309354/"><i class="fab fa-facebook-f"></i></a></li>
        <li><a href="https://twitter.com/limited_tc"><i class="fab fa-twitter"></i></a></li>
        <!-- <li><a href="#"><i class="fab fa-behance"></i></a></li> -->
        <li><a href="https://www.linkedin.com/company/tc-limited"><i class="fab fa-linkedin"></i></a></li>
        <!-- <li><a href="#"><i class="fab fa-google"></i></a></li> -->
    </ul>
</div>
